import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import ContactFormSection from "../containers/contact/contact-form";
import ContactInfoSection from "../containers/contact/contact-info";

export default () => (
  <Layout headerLayout={2}>
    <SEO title="Contacto" />
    <ContactFormSection />
    <ContactInfoSection />
  </Layout>
);

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from '../../../components/shared/section-title'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import Link from '../../../components/shared/link'
import MapContainer from '../../../components/map'
import { SectionWrap, ContactInfoWrap, SingleInfo, MapWrapper } from './contact-info.stc'

const ContactInfo = ({ infoTextStyle, infoHeadingStyle, infoLinkStyle }) => {

    const siteQueryData = useStaticQuery(graphql`
        query contactSiteDataQuery {
            site {
                siteMetadata {
                  contact {
                      chile{
                        company_address
                        company_email
                        phone
                        phone2
                      }
                      peru{
                        company_address
                        company_email
                        phone
                        phone2 
                      }
                  }
                }
            }
        }      
    `);
    const { chile, peru } = siteQueryData.site.siteMetadata.contact
    return (
        <>
            <SectionWrap>
                <div className="row justify-content-between align-items-center">
                    <div className="col-1 offset-1">
                        <ContactInfoWrap>
                            <SectionTitle
                                title="Información de Chile."
                            />
                            {chile.company_address && (
                                <SingleInfo className="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                                    <Text {...infoTextStyle}>Dirección</Text>
                                    <Heading {...infoHeadingStyle}>{chile.company_address}</Heading>
                                </SingleInfo>
                            )}
                            {chile.company_email && (
                                <SingleInfo className="wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1000ms">
                                    <Text {...infoTextStyle}>Email</Text>
                                    <Heading {...infoHeadingStyle}>
                                        <Link {...infoLinkStyle} path={`mailto:${chile.company_email}`}>{chile.company_email}</Link>
                                    </Heading>
                                </SingleInfo>
                            )}

                            <SingleInfo className="wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="1000ms">
                                <Text {...infoTextStyle}>Teléfono</Text>
                                {chile.phone && <Heading {...infoHeadingStyle}><Link {...infoLinkStyle} path="/">{chile.phone}</Link></Heading>}
                            </SingleInfo>
                        </ContactInfoWrap>
                    </div>
                    <div className="col-3 offset-1">
                        <MapWrapper>
                            <MapContainer lat={-33.4053844} lng={-70.5785043} />
                        </MapWrapper>
                    </div>
                </div>
            </SectionWrap>
            <SectionWrap>
                <div className="row justify-content-between align-items-center">

                    <div className="col-3 ">
                        <MapWrapper>
                            <MapContainer lat={-12.1262825} lng={-77.0259264} />
                        </MapWrapper>
                    </div>
                    <div className="col-2 ">
                        <ContactInfoWrap>
                            <SectionTitle
                                title="Información de Perú."
                            />
                            {peru.company_address && (
                                <SingleInfo className="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                                    <Text {...infoTextStyle}>Address</Text>
                                    <Heading {...infoHeadingStyle}>{peru.company_address}</Heading>
                                </SingleInfo>
                            )}
                            {peru.company_email && (
                                <SingleInfo className="wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1000ms">
                                    <Text {...infoTextStyle}>Email</Text>
                                    <Heading {...infoHeadingStyle}>
                                        <Link {...infoLinkStyle} path={`mailto:${peru.company_email}`}>{peru.company_email}</Link>
                                    </Heading>
                                </SingleInfo>
                            )}

                            <SingleInfo className="wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="1000ms">
                                <Text {...infoTextStyle}>Phone</Text>
                                {peru.phone && <Heading {...infoHeadingStyle}><Link {...infoLinkStyle} path="/">{peru.phone}</Link></Heading>}
                            </SingleInfo>
                        </ContactInfoWrap>
                    </div>
                </div>
            </SectionWrap>
        </>
    )
}

ContactInfo.propTypes = {
    infoTextStyle: PropTypes.object,
    infoHeadingStyle: PropTypes.object
}

ContactInfo.defaultProps = {
    infoTextStyle: {
        fontSize: '12px',
        color: '#000',
        opacity: 0.8,
        fontweight: 400,
        texttransform: 'uppercase',
        mb: '5px'
    },
    infoHeadingStyle: {
        as: 'h6',
        fontSize: '12px',
        fontweight: 600,
        color: '#000',
        letterspacing: '0.5px',
        mb: 0,
        lineHeight: '22px'
    },
    infoLinkStyle: {
        color: '#000'
    }
}

export default ContactInfo
